<template>
    <div>
        <ali-pay-ing v-if="payType == 2"></ali-pay-ing>
    </div>
</template>

<script>

import aliPayIng from "@/components/pay/AliPayIng.vue";
export default {
    name: "scanPayCode",
    components: {
        aliPayIng
    },
    data(){
        return{
            data:{},
            msg:'',
            payType:0, //0隐藏1微信2支付宝
            payStatus:0, //0支付中1已支付2重新支付
        }
    },
    mounted: function () {
        this.checkClient();
    },
    methods:{
        checkClient() {
            var that = this;
            if (this.isWeChat()) {
                that.payType = 1;
                that.msg = "当前客户端为微信";
                let searchParams = new URLSearchParams(window.location.search);
                let sign = searchParams.get('sign');
                that.newApi.getCodeUrl({sign:sign}).then((res) => {
                    if(res.isSuccess == 1){
                        //重定向
                        window.location.href = res.data;
                    }
                })
            } else if (this.isAlipay()) {
                that.payType = 2;
                that.msg = "当前客户端为支付宝";
            } else {
                that.payType = 2;
                that.utils.msg("请用微信或者支付宝扫码");
            }
        },
        isWeChat() {
            var ua = window.navigator.userAgent.toLowerCase();
            return /MicroMessenger/i.test(ua);
        },
        isAlipay() {
            var ua = window.navigator.userAgent.toLowerCase();
            return /Alipay/i.test(ua);
        },
    }
}
</script>

<style scoped>
*{
    margin: 0px;
    padding: 0px;
}
#app{
    margin: 0;
}
</style>